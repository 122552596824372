import React from "react";
import Lottie from "lottie-react";
import pageNotFound from "../../asstes/pageNotFound.json";

const NotFound = () => {
  return (
    <div>
      <Lottie
        style={{
          width: "50rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          paddingLeft: "33rem",
          paddingTop: "10rem",
        }}
        animationData={pageNotFound}
        loop={false}
      />
      ;
    </div>
  );
};

export default NotFound;
