import React, { useState } from "react";
import { useCart } from "../../Store/CartContex";
import emptyCart from "../../asstes/emptyCart.json";
import "./Cart.css";
import CloseIcon from "@mui/icons-material/Close";
import { auth } from "../../Auth/firebaseConfig";
import axios from "axios";
import { useLottie } from "lottie-react";
import { Link } from "react-router-dom";

function EmptyCartAnimation() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: emptyCart,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(defaultOptions);
  return (
    <div style={{ paddingTop: "5rem" }} className="empty-cart-animation">
      {View}
    </div>
  );
}

function Cart() {
  const { cart, removeFromCart, clearCart, updateQuantity } = useCart();
  const [quantities, setQuantities] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleQuantityChange = (id, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity > 0) {
      updateQuantity(id, newQuantity);
    }
  };

  const handleRemove = (id) => {
    removeFromCart(id);
  };

  const subTotal = cart.reduce((acc, item) => {
    const quantity = quantities[item.id] || 1;
    return acc + item.price * quantity;
  }, 0);

  const handleSubmitOrder = async (productId, clientMail, quantity) => {
    try {
      const orderData = {
        product_id: productId,
        quantity: quantity,
        client_mail: clientMail,
        address: "string",
      };
      const response = await axios.post(
        "https://hello-magic.onrender.com/add/order/",
        orderData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting the order:", error);
      throw error;
    }
  };

  const handleOrderProcessing = async (email) => {
    let successfulOrders = [];
    let failedOrders = [];
    for (const item of cart) {
      const quantity = quantities[item.id] || 1;
      try {
        await handleSubmitOrder(item.id, email, quantity);
        successfulOrders.push(item.name);
      } catch (error) {
        failedOrders.push(item.name);
      }
    }

    if (successfulOrders.length > 0) {
      alert(
        `Order submitted successfully for items: ${successfulOrders.join(", ")}`
      );
      clearCart();
    }

    if (failedOrders.length > 0)
      alert(`Error submitting the order for items: ${failedOrders.join(", ")}`);
  };

  const handleEmailFormSubmit = async (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    await handleOrderProcessing(email);
  };

  const handleSendEmail = async () => {
    const user = auth.currentUser;
    if (!user) {
      setIsModalOpen(true);
      return;
    }
    await handleOrderProcessing(user.email);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!auth.currentUser && isModalOpen) {
    return (
      <div className="email-form-container">
        <form onSubmit={handleEmailFormSubmit}>
          <button className="close-modal-button" onClick={handleCloseModal}>
            Close
          </button>
          <h2>Enter your email address:</h2>

          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <button type="submit" className="email-submit-button">
            Submit
          </button>
        </form>
      </div>
    );
  }

  if (cart.length === 0) {
    return (
      <div className="cart-container">
        <h2 className="cart-header">Continue shopping</h2>
        <EmptyCartAnimation />
      </div>
    );
  }

  return (
    <div className="cart-container">
      <Link className="this-newlink" to="/Shop">
        <h2 className="cart-header">Continue shopping</h2>
      </Link>
      <div className="cart-wrapper">
        <div className="cart-new">
          <p className="cart-price">Price</p>
          <p className="cart-quantity">Quantity</p>
          <p className="cart-total">Total</p>
        </div>
        <div className="underline-cart"></div>
        {cart.map((item) => (
          <div className="carts-wrapp" key={item.id}>
            <img className="cart-img" src={item.picture_url} alt={item.name} />
            <div className="try">
              <p className="item-price">${item.price}</p>
              <div className="quantity-close-wrapper">
                <input
                  type="number"
                  value={item.quantity}
                  min="1"
                  onChange={(e) => handleQuantityChange(item.id, e)}
                  placeholder="Quantity"
                  className="modal-quantity"
                />
                <CloseIcon
                  className="close-icon"
                  onClick={() => handleRemove(item.id)}
                />
              </div>
              <p className="total">
                ${(item.price * (quantities[item.id] || 1)).toFixed(2)}
              </p>
            </div>
          </div>
        ))}
        <p className="subtotal">Subtotal: ${subTotal.toFixed(2)}</p>
        <button className="send-email" onClick={handleSendEmail}>
          Send to Email
        </button>
      </div>
    </div>
  );
}

export default Cart;
