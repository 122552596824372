import React from "react";

function ProductModal({
  addToCart,
  isModalOpen,
  setIsModalOpen,
  modalProduct,
}) {
  return (
    <div className={`modal ${isModalOpen ? "open" : ""}`}>
      {modalProduct && (
        <div className="modal-content">
          <span className="close" onClick={() => setIsModalOpen(false)}>
            &times;
          </span>
          <img src={modalProduct.picture_url} alt={modalProduct.name} />
          <h2>{modalProduct.name}</h2>
          <p>{modalProduct.description}</p>
          <p>Price: ${modalProduct.price}</p>
          <button
            className="btn-check"
            onClick={() => {
              addToCart(modalProduct);
              console.log("Product ID:", modalProduct.id);
              alert(
                `You have added ${modalProduct.name} to your shopping cart`
              );
            }}
          >
            Add to cart
          </button>
        </div>
      )}
    </div>
  );
}

export default ProductModal;
