import React from "react";
import imgagee from "../../asstes/MGP.svg";
import "./Shop.css";

const Shop = () => {
  return (
    <div className="shop-container">
      <img className="shop-img" src={imgagee} />
    </div>
  );
};

export default Shop;
