import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBfBDWKspq5TdmB9ayAxyyshipwtjVENWc",
  authDomain: "login-76bb5.firebaseapp.com",
  projectId: "login-76bb5",
  storageBucket: "login-76bb5.appspot.com",
  messagingSenderId: "97266594138",
  appId: "1:97266594138:web:0f468864475940f4070363",
  measurementId: "G-CQBGDDKXJD",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
