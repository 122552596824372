import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useCart } from "../../Store/CartContex";
import CategoryFilter from "./CategoryFilter";
import "./ProductList.css";
import { Link, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import prodNotfound from "../../asstes/prodNotfound.json";
import Placeholder from "../../asstes/Placeholder.json";
import Lottie from "lottie-react";
import PriceFilter from "./PriiceFilter";
import ProductModal from "./ProductModal/ProductModal";

const fetchProducts = async () => {
  const response = await axios.get("https://hello-magic.onrender.com/products");
  return response.data;
};

function Products({ selectedProductId }) {
  const {
    data: products,
    isLoading,
    isError,
  } = useQuery("products", fetchProducts);
  const { addToCart } = useCart();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState("none");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();
  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     setPadding("9rem");
  //   } else if (location.pathname === "/Shop") {
  //     setPadding("4rem");
  //   } else {
  //     setPadding("0");
  //   }
  // }, [location.pathname]);
  const categories = products
    ? [...new Set(products.map((p) => p.category))]
    : [];
  const sortProducts = (products) => {
    if (sortOrder === "asc") {
      return [...products].sort((a, b) => a.price - b.price);
    }
    if (sortOrder === "desc") {
      return [...products].sort((a, b) => b.price - a.price);
    }
    return products; // return unsorted for 'none'
  };
  useEffect(() => {
    if (!products) return;
    const sortedProducts = sortProducts(products);
    setFilteredProducts(
      selectedCategory
        ? sortedProducts.filter(
            (product) => product.category === selectedCategory
          )
        : sortedProducts
    );
  }, [selectedCategory, products, sortOrder]);

  useEffect(() => {
    if (!products) return;
    setFilteredProducts(
      selectedCategory
        ? products.filter((product) => product.category === selectedCategory)
        : products
    );
  }, [selectedCategory, products]);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error occurred while fetching products.</p>;
  const excludedPaths = ["/Header"];
  const filterCat = [
    "/",
    "/Header",
    "/Login",
    "/CreateAccount",
    "/RessetPassword",
    "/MyAccount",
    "/cart",
  ];

  const handlePhotoClick = (product) => {
    console.log("Photo clicked:", product);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <section className="product-container" id="Products">
      <div className="filters-wrapper">
        {filterCat.indexOf(location.pathname) === -1 && (
          <CategoryFilter
            categories={categories}
            onSelectCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
        )}
        {filterCat.indexOf(location.pathname) === -1 && (
          <PriceFilter
            categories={categories}
            onSelectCategory={setSelectedCategory}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
        )}
      </div>
      {excludedPaths.indexOf(location.pathname) === -1 && (
        <Header
          categories={categories}
          onSelectCategory={setSelectedCategory}
          products={products}
          setFilteredProducts={setFilteredProducts}
        />
      )}
      <div className="border-container">
        {filteredProducts && filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="borders">
              <div className="image-container">
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: Placeholder,
                  }}
                  height={150}
                  width={150}
                  style={{ position: "absolute" }}
                />
                <img
                  className="img-border"
                  src={product.picture_url}
                  alt={product.name}
                  onClick={() => handlePhotoClick(product)}
                />
              </div>
              <div className="checkout-btn">
                <div className="products-wrppaer-new">
                  <h2 className="header-border">{product.name}</h2>
                  <p className="p-border">{product.description}</p>
                  <p className="price-border">Price: ${product.price}</p>
                </div>

                <button
                  className="btn-check"
                  onClick={() => {
                    addToCart(product);
                    console.log("Product ID:", product.id);
                    alert(
                      `You have added ${product.name} to your shopping cart`
                    );
                  }}
                >
                  Add to cart
                </button>

                {product.id === selectedProductId && (
                  <p>
                    This is the selected product with ID: {selectedProductId}
                  </p>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>
            <Lottie animationData={prodNotfound} loop={false} />;
          </div>
        )}
        {isModalOpen && (
          <ProductModal
            addToCart
            product={selectedProduct}
            onClose={handleCloseModal}
          />
        )}
      </div>
    </section>
  );
}

export default Products;
