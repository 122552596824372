// src/App.js
import React, { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Index from "./Router/Index";
import AgeVerification from "./component/AgeVerification/AgeVerification";

const queryClient = new QueryClient();

function App() {
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const savedVerificationStatus = localStorage.getItem("isVerified");
    if (savedVerificationStatus === "true") {
      setIsVerified(true);
    }
  }, []);

  const handleVerification = (verified) => {
    setIsVerified(verified);
  };

  return (
    <QueryClientProvider client={queryClient}>
      {isVerified ? (
        <Index />
      ) : (
        <AgeVerification onVerify={handleVerification} />
      )}
    </QueryClientProvider>
  );
}

export default App;
