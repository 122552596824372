import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from "../Store/CartContex";
import Home from "../component/Home/HomeSection";
import Products from "../component/Products/Products";
import InfoText from "../component/Products/InfoText/InfoText";
import Header from "../component/Header/Header";
import UserLogin from "../component/User/UserLogin";
import CreateAccount from "../component/User/CreateAccount";
import ResetPass from "../component/User/ResetPass";
import MyAccount from "../component/User/MyAccount";
import Cart from "../component/Cart/Cart";
import NotFound from "../component/NotFound/NotFound";
import ContacUs from "../component/Contact/ContacUs";
import Shop from "../component/Products/Shop";
import BottomSvg from "../component/Products/BottomSvg";
import Wrapper from "../component/Products/Wrapper";
import ProductDetailsPage from "../component/Products/ProductModal";
import Newsvg from "../component/Products/Newsvg";

const Index = () => {
  return (
    <Router>
      <CartProvider>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={[
                <Header />,
                <Shop />,
                <Home />,
                <Products />,
                <InfoText />,
              ]}
            />
            <Route
              path="/Login"
              element={[<Header />, <UserLogin />, <InfoText />]}
            />
            <Route
              path="/CreateAccount"
              element={[<Header />, <CreateAccount />, <InfoText />]}
            />
            <Route
              path="/RessetPassword"
              element={[<Header />, <ResetPass />, <InfoText />]}
            />
            <Route path="/MyAccount" element={[<Header />, <MyAccount />]} />
            <Route
              path="/shop"
              element={[<Newsvg />, <Products />, <BottomSvg />, <InfoText />]}
            />
            <Route
              path="/cart"
              element={[<Header />, <Cart />, <InfoText />]}
            />
            <Route path="/ContactUs" element={[<Header />, <ContacUs />]} />
            <Route path="/header" element={<Header />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/products" element={<ProductDetailsPage />} />
          </Routes>
        </div>
      </CartProvider>
    </Router>
  );
};

export default Index;
