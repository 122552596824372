import React, { useState, useEffect } from "react";
import "./ProductModal.css"; // Create a CSS file to style your modal
import { useCart } from "../../../Store/CartContex";

// Star Rating Component
function StarRating({ rating, onRating }) {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <span
          key={star}
          className={star <= rating ? "star filled" : "star"}
          onClick={() => onRating(star)}
        >
          ★
        </span>
      ))}
    </div>
  );
}

// Modal Component
function ProductModal({ product, onClose }) {
  const { addToCart } = useCart();
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const savedRating = localStorage.getItem(`rating-${product.id}`);
    if (savedRating) {
      setRating(Number(savedRating));
    }
  }, [product.id]);

  function handleRating(newRating) {
    setRating(newRating);
    localStorage.setItem(`rating-${product.id}`, newRating);
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="left">
          <img src={product.picture_url} alt={product.name} />
        </div>
        <div className="right">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <h2 className="prod-head-">{product.name}</h2>
          <p className="prod-desc-">{product.description}</p>
          <p className="prod-price-">Price: ${product.price}</p>
          <StarRating rating={rating} onRating={handleRating} />
          <button
            className="btn-check"
            onClick={() => {
              addToCart(product);
              console.log("Product ID:", product.id);
              alert(`You have added ${product.name} to your shopping cart`);
            }}
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductModal;
