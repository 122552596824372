import React, { useEffect, useState } from "react";
import { auth } from "../../Auth/firebaseConfig";
import { useLottie } from "lottie-react";
import yourprof from "../../asstes/yourprof.json";
import { onAuthStateChanged, signOut } from "firebase/auth";

function EmptyCartAnimation() {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: yourprof,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(defaultOptions);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        height: "100vh",
      }}
      className="empty-cart-animation"
    >
      {View}
    </div>
  );
}

const AuthDetails = () => {
  const [authUser, setAuthUser] = useState(null);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("signout ");
      })
      .catch((error) => console.log(error));
  };

  const handleFileInput = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        localStorage.setItem("photo", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    if (authUser) {
      localStorage.setItem(`username-${authUser.uid}`, newUsername);
    }
    setShowUsernameInput(false); // Hide input field after setting username
  };

  return (
    <div>
      {authUser ? (
        <div className="my-acc-cont">
          <p className="sign-out-cr">{`Signed In as ${
            username || authUser.email
          }`}</p>
        </div>
      ) : (
        <p>{`Signed Out`}</p>
      )}
    </div>
  );
};

export default AuthDetails;
