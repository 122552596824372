import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import "./ContactUs.css";

const ContacUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_geotyzp",
        "template_5mresne",
        form.current,
        "QNzrcAs0Ziv6R6Afj"
      )
      .then(
        (result) => {
          alert(`email sent successfully`);
          console.log(result.text);
        },
        (error) => {
          alert(`couldn't send email`);
          console.log(error.text);
        }
      );
  };

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <h1 className="login-header">MAGIC GENETICS</h1>
        <p className="login-para">CANNABIS SEEDBANK | MAGIC GENETICS</p>
        <p className="acc-signin">CONTACT US</p>
        <div className="login-underline"></div>

        <form ref={form} onSubmit={sendEmail}>
          <div className="login-form">
            <input
              placeholder="Email address"
              type="text"
              className="input-email"
              required
            />
            <input
              placeholder="Name"
              type="text"
              className="input-email"
              required
              name="from_name"
            />

            <textarea
              required
              className="message"
              placeholder="message"
              name="message"
              style={{
                resize: "none",
              }}
            />
            <button type="Submit" className="signin-btn">
              Send
            </button>
            <p className="not-member">
              Not a member?
              <Link className="this-newlink" to="/CreateAccount">
                <span className="create-acc">Create account</span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContacUs;
