import React from "react";
import "./CategoryFilter.css";

function CategoryFilter({ onSelectCategory, setSortOrder, sortOrder }) {
  const categories = ["Prints", "Led's", "Bubblebags", "Kief Sifter Machine"];
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  return (
    <div className="category-filter">
      <div className="category-dropdown">
        <h2>Filter by category</h2>
        <select
          onChange={(e) =>
            onSelectCategory(e.target.value === "All" ? null : e.target.value)
          }
          className="category-select"
        >
          <option value="All">All</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default CategoryFilter;
