import React from "react";
import mege from "../../asstes/mg_1_.svg";
import "./Home.css";
import homeimg from "../../asstes/magiclogo.svg";

const Home = () => {
  return (
    <section className="home-section" id="Home">
      {/* <h1>MAGIC GENETICS</h1>
      <p className="first-para">CANNABIS SEEDBANK | MAGIC GENETICS</p> */}
      {/* // eslint-disable-next-line jsx-a11y/alt-text */}
      {/* <img className="img-log-main" src={mege} /> */}
      <div className="home-container">
        <img className="img-img" src={homeimg} alt="" />
      </div>
      {/* <p className="second-para">
        photography of the worlds best cannabis seedbank
      </p> */}
      {/* <button className="btn-home">SEE PRINTS</button> */}
    </section>
  );
};

export default Home;
