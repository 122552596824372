/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import imiii from "../../asstes/mgwhite.png";

const BottomSvg = () => {
  return (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <div className="shop-container">
      <img className="shop-img" src={imiii} />
    </div>
  );
};

export default BottomSvg;
