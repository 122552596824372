import React, { useState } from "react";
import "./UserLogin.css";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Auth/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((useCredential) => {
        console.log(useCredential);
        alert(`You have successfully as ${useCredential}`);
        navigate("/MyAccount");
      })
      .catch((error, useCredential) => {
        console.log(error);
        alert(`Faild to Log In please check your email or password`);
      });
  };
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <h1 className="login-header">MAGIC GENETICS</h1>
        <p className="login-para">CANNABIS SEEDBANK | MAGIC GENETICS</p>
        <p className="acc-signin">ACCOUNT SIGN IN</p>
        <div className="login-underline"></div>
        <p className="underline-para">
          Sign in to your account to access your profile, history, and any
          private pages you've been granted access to.
        </p>
        <form onSubmit={signIn}>
          <div className="login-form">
            <input
              placeholder="Email address"
              type="text"
              className="input-email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Password"
              type="password"
              className="input-pass"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="Submit" className="signin-btn">
              LOG IN
            </button>
            <Link className="this-newlink" to="/RessetPassword">
              <p className="reset-pass">Reset password</p>
            </Link>

            <p className="not-member">
              Not a member?
              <Link className="this-newlink" to="/CreateAccount">
                <span className="create-acc">Create account</span>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
