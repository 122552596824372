import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { auth } from "../../Auth/firebaseConfig";

const UserInfo = ({ toggleUserInfo }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="user-wrapper">
      <div className="auth-part">
        {isLoggedIn ? (
          <a>
            <span
              style={{ paddingLeft: ".5rem" }}
              onClick={() => auth.signOut()}
            >
              LOG OUT
            </span>
          </a>
        ) : (
          <a>
            <Link to="/Login" onClick={toggleUserInfo}>
              SIGN IN
            </Link>
          </a>
        )}

        <a>
          <Link onClick={toggleUserInfo} to="/CreateAccount">
            CREATE ACCOUNT
          </Link>
        </a>
      </div>
      <span className="empty-span"></span>
      <div className="order-part">
        {/* <a>
          <Link to="/Login">ORDERS</Link>
        </a> */}
        {isLoggedIn ? (
          <a>
            <Link onClick={toggleUserInfo} to="/MyAccount">
              MY ACCOUNTT
            </Link>
          </a>
        ) : (
          <a>
            <Link onClick={toggleUserInfo} to="/LogIn">
              MY ACCOUNT
            </Link>
          </a>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
