import React from "react";
import "./UserLogin.css";
import { Link } from "react-router-dom";

const ResetPass = () => {
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <h1 className="login-header">MAGIC GENETICS</h1>
        <p className="login-para">CANNABIS SEEDBANK | MAGIC GENETICS</p>
        <p className="acc-signin">RESET PASSWORD</p>
        <div className="login-underline"></div>
        <p className="underline-para">
          w nter your email address, and we’ll send you a password reset link.
        </p>
        <div className="login-form">
          <input
            placeholder="Email address"
            type="text"
            className="input-email"
            required
          />

          <button style={{ width: "10vw" }} className="signin-btn">
            SEND RESET LINK
          </button>

          <p className="not-member">
            Don't need to reset your password?
            <Link className="this-newlink" to="/Login">
              <span className="create-acc"> Sign in</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
