import React, { useState } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UserInfo from "./UserInfo";
import { useCart } from "../../Store/CartContex";
import { Link } from "react-router-dom";

const Header = ({ products, setFilteredProducts }) => {
  const { cart } = useCart();
  const [menuOpen, setMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(false);
  const [search, setSearch] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };
  const handleScrollDown = () => {
    window.scrollBy(0, 1000);
  };

  const itemCount = cart.reduce((total, item) => {
    return total + (Number.isInteger(item.quantity) ? item.quantity : 0);
  }, 0);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearch(searchValue);

    if (!products) return;

    if (searchValue.trim() === "") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.name
          ? product.name.toLowerCase().includes(searchValue.toLowerCase())
          : false
      );
      setFilteredProducts(filtered);
    }
  };

  return (
    <div className="header-container">
      <div className="menu-icon-wrapper">
        <IconButton onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? (
            <CloseIcon style={{ fontSize: "2rem", color: "white" }} />
          ) : (
            <MenuIcon style={{ fontSize: "2rem", color: "white" }} />
          )}
        </IconButton>
      </div>
      <div className={`links-wrapper ${menuOpen ? "active" : ""}`}>
        <ul>
          <Link className="this-newlink" to="/">
            <li
              style={{ color: "white" }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              HOME
            </li>
          </Link>
          <Link className="this-newlink" to="/Shop">
            <li
              style={{ color: "white" }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              SHOP
            </li>
          </Link>
          <Link className="this-newlink" to="/ContactUs">
            <li
              style={{ color: "white" }}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              CONTACT US
            </li>
          </Link>
        </ul>
      </div>
      <div className="icons-wrapper">
        <SearchIcon
          onClick={() => {
            setIsSearchOpen(!isSearchOpen);
          }}
          style={{
            fontSize: "2rem",
            cursor: "pointer",
            display: isMobileDevice() ? "none" : "flex",
            color: "white",
          }}
        />

        {isSearchOpen && (
          <div className={`search-wrapper ${isSearchOpen ? "open" : ""}`}>
            <input
              type="text"
              value={search}
              onChange={handleSearchChange}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleScrollDown();
                }
              }}
              placeholder="Search by Category"
            />
          </div>
        )}
        <input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Search by Name"
          className="search-input"
          style={{ display: isMobileDevice() ? "flex" : "none" }}
        />
        <Link className="this-newlink" to="/cart">
          <div className="shopp-ing">
            <ShoppingCartIcon
              style={{
                fontSize: "2rem",
                cursor: "pointer",
                color: "white",
              }}
            />
            <div className="cart-counter">{itemCount}</div>
          </div>
        </Link>
        <div className="icon-wrapper">
          <PersonIcon
            onClick={() => setUserInfo(!userInfo)}
            style={{ fontSize: "2rem", cursor: "pointer", color: "white" }}
          />
          {userInfo ? (
            <UserInfo toggleUserInfo={() => setUserInfo(false)} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
