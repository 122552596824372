import React from "react";
import "./InfoText.css";

const InfoText = () => {
  return (
    <div>
      <div className="bottom-div">
        {/* <h1 className="wrapp-header-all">
          Disclaimer: Germination of Cannabis/Hemp Seeds
        </h1> */}
        <h1 className="info-head">Warning!</h1>
        <p className="bottom-para">
          Germination of cannabis/hemp seed is illegal in most counries, <br />
          These seeds are sold by magic Genetics as collectable adult souvenirs
          to help preserve the cammabis/hemp genetics for future generations.
          <br />
          Any information on the packaging of the seeds, marketing material or
          websites,
          <br />
          is given for the educational purpose or purposes of differentiation.
          <br />
          It is not intended to condone, promote or incite the use of illegal or
          controlled substances. As a magicgenetics.com customer,
          <br />
          you are prohibited from distributing seeds we supply to countries
          where possession of and/or trafficking in Cannabis/hemp seeds or other
          seeds is illegal.
          <br />
          By selling cannabis/hemp sativa seeds to persons unable to grow
          cannabis, we are helping to cirulate and preserve the pool of
          cannabis/hemp genetics.
          <br />
          The illegality of cannabis/hemp has caused a major reduction in its
          gene pool, leading to a higher risk of extinction of major
          cannabis/hemp seed strains.
          <br />
          This cannot be allowed to happen it is our responsibility and human
          right to protect Natures genetics.
          <br />
          WE HOLD NO RESPONSIBILITY FOR ANYONE BREAKING THE LAWS OF THERE
          COUNTRY/STATE.
          <br />
          PRIVACY POLICYTERMS AND CONDITIONS
        </p>

        <h1 className="bottom-head"> Powerd by Magic Genetics</h1>
        {/* <p className="bottom-para">
          <span className="first-span">Introduction:</span> Magic Genetic is a
          company based in Spain, specializing in the sale of cannabis/hemp
          seeds. We want to emphasize that the germination of cannabis/hemp
          seeds may be illegal in many countries, including the United States.
          At Magic Genetic, we offer these seeds exclusively as collectible
          adult souvenirs with the primary aim of preserving cannabis/hemp
          genetics for future generations. Our commitment is to provide
          educational information and promote differentiation rather than
          condone, endorse, or incite the use of illegal or controlled
          substances.
        </p>
        <p className="bottom-para">
          <span className="first-span">Legal Compliance:</span> As a Magic
          Genetic customer, it is your responsibility to be aware of and abide
          by the laws and regulations regarding the possession and use of
          cannabis/hemp seeds in your respective country or state. Magic Genetic
          is a Spanish company, and our sales primarily target customers in
          Europe. We strictly prohibit the distribution of seeds to countries
          where the possession, cultivation, or trafficking of cannabis/hemp
          seeds or related products is illegal. By purchasing seeds from Magic
          Genetic, you are supporting the preservation of cannabis/hemp
          genetics.
        </p>{" "}
        <p className="bottom-para">
          <span className="first-span">Preservation of Genetic Diversity:</span>{" "}
          The illegal status of cannabis/hemp in some regions has led to a
          significant reduction in the genetic diversity of these plants. This,
          in turn, poses a risk to the survival of vital cannabis/hemp seed
          strains. At Magic Genetic, we believe it is our responsibility and a
          fundamental human right to protect and preserve nature's genetic
          diversity for future generations.
        </p>
        <p className="bottom-para">
          <span className="first-span">Customer Responsibility:</span> Magic
          Genetic holds no responsibility for customers who knowingly or
          unknowingly violate the laws of their respective country or state. It
          is incumbent upon our customers to understand and comply with local
          laws regarding the possession, cultivation, and use of cannabis/hemp
          seeds and related products.
        </p>
        <p className="bottom-para">
          <span className="first-span">Conclusion: </span> Magic Genetic remains
          committed to providing high-quality cannabis/hemp seeds for
          educational and preservation purposes. We strongly discourage any
          illegal activities and encourage responsible and legal usage of our
          products. We are dedicated to safeguarding the genetic diversity of
          cannabis/hemp strains and promoting responsible practices within the
          boundaries of the law. Thank you for choosing Magic Genetic as your
          source for cannabis/hemp seeds.
        </p>*/}
      </div>
    </div>
  );
};

export default InfoText;
