import React from "react";

const PriceFilter = ({ setSortOrder, sortOrder }) => {
  return (
    <div className="price-filter">
      <h2>Sort By</h2>
      <div className="price-filter">
        <select
          onChange={(e) => setSortOrder(e.target.value)}
          value={sortOrder}
          placeholder="Sort by"
        >
          <option value="asc">Price: Low to High</option>
          <option value="desc">Price: High to Low</option>
        </select>
      </div>
    </div>
  );
};

export default PriceFilter;
