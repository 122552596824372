import React, { useEffect, useState } from "react";
import "./UserLogin.css";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Auth/firebaseConfig";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((useCredential) => {
        console.log(useCredential);
        alert("User has been created");
        navigate("/MyAccount");
      })

      .catch((error) => {
        console.log(error);
      });
  };
  const [authUser, setAuthUser] = useState(null);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  const userSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("signout ");
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      {authUser ? (
        <div className="accc-cont">
          <p className="sign-out-cr">
            {`You are Signed In as `}
            <span className="user-sign-out"> {`${authUser.email},`}</span>{" "}
            {`if you want to create a new email you need to Sign Out`}
          </p>
          <button className="acc-btn" onClick={userSignOut}>
            Sign Out
          </button>
        </div>
      ) : (
        <div className="login-container">
          <div className="login-wrapper">
            <h1 className="login-header">MAGIC GENETICS</h1>
            <p className="login-para">CANNABIS SEEDBANK | MAGIC GENETICS</p>
            <p className="acc-signin">CREATE ACCOUNT</p>
            <div className="login-underline"></div>
            <p className="underline-para">
              By creating an account, you may receive newsletters or promotions.
            </p>
            <form onSubmit={signUp}>
              <div className="login-form">
                <input
                  required
                  placeholder="First name"
                  type="text"
                  className="input-email"
                />
                <input
                  required
                  placeholder="Last name"
                  type="text"
                  className="input-pass"
                />
                <input
                  required
                  placeholder="Email"
                  className="input-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  placeholder="Password"
                  type="password"
                  className="input-email"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="signin-btn">SIGN UP</button>
                <Link className="this-newlink" to="/RessetPassword">
                  <p className="reset-pass">Reset password</p>
                </Link>
                <p className="not-member">
                  Already have an account?
                  <Link className="this-newlink" to="/Login">
                    <span className="create-acc">Sign In</span>{" "}
                  </Link>
                </p>
              </div>{" "}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UserLogin;
