import React from "react";
import "./AGeVerification.css";

function AgeVerification({ onVerify }) {
  const handleVerification = (isAdult) => {
    if (isAdult) {
      localStorage.setItem("isVerified", "true");
      onVerify(true);
    } else {
      window.location.href = "https://google.com";
    }
  };

  return (
    <div className="age-container">
      <h1 className="age-h1">Welcome to MagicGenetics</h1>
      <div className="age-border">
        <p className="age-p">Are you 18 years old or older?</p>
        <button className="age-true" onClick={() => handleVerification(true)}>
          YES
        </button>
        <button className="age-false" onClick={() => handleVerification(false)}>
          NO
        </button>
      </div>
    </div>
  );
}

export default AgeVerification;
